import { ThemeProvider } from '@mui/material'
import { TERRORCODE, TGLOBAL, createThemeWithMode } from '@sicarx/sxw-core'
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { DrawerM, NotificationDialog, ServerPrint, SicarLoading, useAlert } from '@sicarx/sxw-components';
import { IndexRoutes } from './routes'
import { PrinterSettingsEntity, RootLocalState, useAuthAccountStore, useAuthCompanyStore, useHandlerUpgradeNotificationStore, useLocalStore } from '@sicarx/sxw-api';
import { UpdatePaymentCard } from './components/UpdatePaymentCard';
import { useTranslation } from "react-i18next";
import shallow from 'zustand/shallow';

const Feedback = lazy(() => import("@sicarx/sxw-components").then(({ Feedback }) => ({ default: Feedback })));
const TimbresDialog = lazy(() => import("@sicarx/sxw-components").then(({ TimbresDialog }) => ({ default: TimbresDialog })));
const UpdatePermissionsAlert = lazy(() => import("./components/UpdatePermissionsAlert").then(({ UpdatePermissionsAlert }) => ({ default: UpdatePermissionsAlert })));
const SubscriptionDialog = lazy(() => import("@sicarx/sxw-components").then(({ SubscriptionDialog }) => ({ default: SubscriptionDialog })));

function App() {
    let snackBarOffline: SnackbarKey;

    const [isMounted, setIsMounted] = useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isDarkMode = useLocalStore(state => state.darkMode.isDarksMode);
    const isDevelopment = import.meta.env.VITE_TESTING === 'PROD';
    const mode = isDarkMode ? 'dark' : 'light';
    const { setShowing, handlerUpgradeNotification: { notifications, showing }, /* resetHandlerUpgradeNotification, setNotification, */ cleanShowed, setShowed } = useHandlerUpgradeNotificationStore();
    const { t } = useTranslation();
    const { /* Alert, dialogProps, */ openDialog/* , closeDialog */ } = useAlert();

    //Development
    const theme = useMemo(() => createThemeWithMode(mode, localStorage?.getItem("mainColor") ? false : isDevelopment), [mode, localStorage?.getItem("mainColor") ? false : isDevelopment]);

    const accountStatus = useAuthAccountStore(store => store.authAccount.status);
    const isCompanyLogged = useAuthCompanyStore(store => store.authCompany.logged);
    const hideVerifyDialog = useAuthCompanyStore(store => store.authCompany.hideVerifyDialog);
    const isInitConfigComplete = useAuthCompanyStore(store => store.authCompany.isInitConfigComplete);
    const checkStatus = useAuthAccountStore(store => store.checkStatus);
    const getPrintersSettings: PrinterSettingsEntity[] = useLocalStore((store: RootLocalState) => store?.printerSettings, shallow);
    /* const hideVerifyAccountDialog = useAuthCompanyStore(store => store.hideVerifyAccountDialog); */


    //Production
    //const theme = useMemo(() => createThemeWithMode(mode), [mode]);
    useEffect(() => {
        if (notifications.length && showing === false) {
            setShowing(true).then(() => {

                notifications.filter((x) => x.showed === false).forEach(async (_, index) => {
                    /* enqueueSnackbar(`${x.url}-${x.code}`, {
                        variant: 'info',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }); */
                    await NotificationDialog({
                        type: "info",
                        description: "Servicio en mantenimiento, intenta de nuevo más tarde"
                    })
                    setShowed(notifications[index].id)
                })
            }).finally(() => {
                setTimeout(() => {
                    cleanShowed()
                    setShowing(false)
                }, 3000);
            })
        }
    }, [notifications, showing])
    useEffect(() => {
        if (notifications.length && showing === false) {
            setShowing(true).then(() => {
                notifications.filter((x) => x.showed === false).forEach(async (_, index) => {
                    await NotificationDialog({
                        type: "info",
                        description: `${t(TERRORCODE.GENERIC_423, { ns: "ERRORCODE" })}`
                    })
                    setShowed(notifications[index].id)
                })
            }).finally(() => {
                setTimeout(() => {
                    cleanShowed()
                    setShowing(false)
                }, 3000);
            })
        }
    }, [notifications, showing])

    useEffect(() => {
        setIsMounted(true);

        //console.log("location", location)
        window.addEventListener("online", () => {
            enqueueSnackbar('Conectado', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
            closeSnackbar(snackBarOffline)
        });
        window.addEventListener("offline", () => {
            snackBarOffline = enqueueSnackbar('Desconectado', {
                variant: 'error',
                persist: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            });
        });

        return () => {
            setIsMounted(false);

            window.removeEventListener("offline", () => {
                console.log("Estamos fuera de linea")
            });
            window.removeEventListener("online", () => {
                console.log("Estamos en linea")

            });
        }
    }, []);

    useEffect(() => {
        if (!hideVerifyDialog && isMounted && isCompanyLogged && isInitConfigComplete && accountStatus === 0)
            setTimeout(() => {
                openDialog({
                    title: `${t(TGLOBAL.WARNING, { ns: 'global' })}`,
                    message: 'Recuerda verificar tu correo electrónico',
                });
            }, 500);
    }, [isMounted, hideVerifyDialog, isCompanyLogged, isInitConfigComplete, accountStatus]);

    useEffect(() => {
        if (isMounted) {
            if (accountStatus !== 1)
                (async () => await checkStatus())();
        }
    }, [isMounted]);

    return (<>
        <ThemeProvider theme={theme}>
            {location.pathname === "/updatePaymentCard" ?
                <UpdatePaymentCard />
                :
                location.pathname === "/buyChips" ?
                    <>
                        <SicarLoading />
                        <TimbresDialog fromMobile />
                    </>
                    :
                    <>
                        {
                            location.pathname === "/didCompleteSubscription" ?
                                <Suspense>
                                    <SicarLoading />
                                    <IndexRoutes />
                                </Suspense>
                                :
                                <>
                                    {getPrintersSettings?.length > 0 && location?.host?.includes("localhost") && <ServerPrint />}
                                    <DrawerM />
                                    <Suspense>
                                        <TimbresDialog />
                                    </Suspense>
                                    <Suspense>
                                        <SubscriptionDialog />
                                    </Suspense>
                                    <SicarLoading />
                                    <Suspense>
                                        <Feedback />
                                    </Suspense>
                                    <Suspense>
                                        <IndexRoutes />
                                    </Suspense>
                                    <Suspense>
                                        <UpdatePermissionsAlert />
                                    </Suspense>
                                </>
                        }
                    </>
            }
        </ThemeProvider>
    </>)
}

export default App;