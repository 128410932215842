import { RootAuthAccountState, useAuthAccountStore, useAuthCompanyStore, useResetStores } from "@sicarx/sxw-api"
import { Grid, TextField, Typography, IconButton, CircularProgress, Alert, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react";
/* import { useResetState } from "@sicarx/sxw-components" */
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles';
import {
    TACCOUNT, TGLOBAL, useFormik, getLoginSchema,
    cipherPassword, Sicarx_Vertical_Light_Icon
} from "@sicarx/sxw-core";

import shallow from 'zustand/shallow';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
/* import { PaymentComponent } from "./Payment"; */


const TextFieldStyled = styled(TextField)({
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)'
    },
    '& .Mui-error': {
        color: '#FF3B30'
    },
    '& .MuiInput-root:before': {
        borderBottom: '1px solid rgba(60, 60, 67, 0.3)',

    },
    '& .MuiInput-root:hover:before': {
        borderBottom: '1px solid rgba(60, 60, 67, 0.3) !important',
    }
});

export const UpdatePaymentCard = (() => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const { logoutResetState } = useResetStores()

    /* ---------- QUERY PARAMS ---------- */
    const EMAIL_PARAM = searchParams.get('email');
    const PASSWORD_PARAM = searchParams.get('p');

    /* ----------------- SESSION STORAGE ----------------- */

    const { login, authAccount, logoutAccount } = useAuthAccountStore((store: RootAuthAccountState) => ({
        login: store.loginAccount,
        authAccount: store.authAccount,
        logoutAccount: store.logoutAccount,
        getJwtAccount: store.getJwtAccount,
        checkStatus: store.checkStatus
    }), shallow);

    const loginCompany = useAuthCompanyStore(store => store.loginCompany);

    /* ----------------- STATE ----------------- */
    /* const [jwtCompany, setJwtCompany] = useState<string>(""); */
    const [isLoading, setIsLoading] = useState(false);
    /* const [showPayments, setShowPayments] = useState(false); */
    const [showAlert, setShowAlert] = useState(false);
    const [lockSlider, setLockSlider] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const loginFn = async (email: string, password: string) => {
        return new Promise(async resolve => {
            setLockSlider(true);
            setShowAlert(false);
            setIsLoading(true);

            const isLogged = await login(email, password, cipherPassword(email), `${navigator.userAgent.trim()}`.substring(0, 127).trim(), "Web");
            /* console.log("isLogged", isLogged) */

            if (isLogged) {
                setLockSlider(false);
                setIsLoading(false);
                setShowAlert(false);
                resolve(true);
            }
            else {
                logoutAccount()
                logoutResetState()
                setLockSlider(false);
                setIsLoading(false);
                setShowAlert(true);
                resolve(false);
            }
        });
    }

    /* ----------------- FORMIK ----------------- */
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: getLoginSchema(t),
        onSubmit: async (formData) => {
            if (!lockSlider)
                await loginFn(formData.email.trim(), cipherPassword(formData.password));

        }
    });

    /*    useEffect(() => {
           if (authAccount.language && authAccount.language !== "" && authAccount.language !== null) {
               localStorage.setItem('lng', authAccount.language);
               i18n.changeLanguage(authAccount.language);
           }
       }, [authAccount.language]); */

    /* ----------------- EFFECTS ----------------- */

    useEffect(() => {
        (async () => {
            console.log("authAccount.logged", authAccount.logged)
            if (authAccount.logged) {
                /* const companies = await getCompanies(true); */
                /* console.log("companies", companies) */
                const branchOfficeId = Number(location?.search?.split("&")[0]?.split("=")[1]) ?? 0;
                const companyUuid = location?.search?.split("&")[1]?.split("=")[1]
                // Obtiene la lista de branches para la unica company registrada
                /* const companyUuid = companies.find((x: any) => x.name === "Company SalesQueries 1 Branch 1")?.uuid */
                /* const branchesCompanies: BranchOfficeEntity[] | null = await getBranchCompanies(companyUuid); */
                /* const branchOffice = branchesCompanies && branchesCompanies[0]; */
                /* const jwt = await getJwtCompany(branchOfficeId, authAccount.jwt,
                    cipherPassword(authAccount.email.trim()),
                    `${navigator.userAgent.trim()}`.substring(0, 127).trim(), "web"); */
                /*  if (jwt !== "") {
                     setJwtCompany(jwt);
                 } */
                const response: any = await loginCompany(branchOfficeId, authAccount.jwt, companyUuid, cipherPassword(authAccount.email.trim()), `${navigator.userAgent.trim()}`.substring(0, 127).trim(), "web", true);
                /* console.log("response", response)
                console.log("Hoy", new Date().getTime())
                console.log("NoAfter", response.xNoAfter)
                console.log("NoAfter", new Date(response.xNoAfter).getTime()) */

                if (response?.xStatus === "2" && (new Date().getTime() < (new Date(response?.xNoAfter)?.getTime()))) {
                    logoutAccount()
                    logoutResetState()
                    window.location.replace(`${location.origin}}`);
                } else if (response?.status === "success") {
                    /* console.log("a pagar") */
                    /*     secureSessionStorage.setItem("tmpBranchId", branchOffice?.id ?? 0)
                        secureSessionStorage.setItem("tmpAuth", jwt)
                        secureSessionStorage.setItem("tmpLocale", "es-MX")
                        secureSessionStorage.setItem("tmpTimezone", "America/Mexico_City") */
                    window.location.replace(`${location.origin}/didCompleteSubscription?tmpLocale=es-MX&tmpTimezone=America/Mexico_City&tmpAuth=${response.jwt}&tmpBranchId=${branchOfficeId}&x-status=${response.xStatus}&x-payment=${response.xPayment}`);
                } else {
                    logoutAccount()
                    logoutResetState()
                }
            }
        })()
    }, [authAccount.logged])

    useEffect(() => {
        /* if (isMounted) */
        if (EMAIL_PARAM !== null && EMAIL_PARAM !== '' && PASSWORD_PARAM === null)
            formik.setFieldValue('email', EMAIL_PARAM);
        else if (EMAIL_PARAM !== null && EMAIL_PARAM !== '' && PASSWORD_PARAM !== null && PASSWORD_PARAM !== '') {
            formik.setFieldValue('email', EMAIL_PARAM);
            formik.setFieldValue('password', PASSWORD_PARAM);
        }
    }, []);

    return (
        <>
            {/* {showPayments ? <PaymentComponent /> : */}
            <Grid container sx={{
                minHeight: "100%", height: "100%", overflow: "hidden", justifyContent: "center", backgroundColor: "#f5f5f8"
            }}>
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    flexDirection="column"
                    sx={{
                        height: '100vh',
                        scrollbarWidth: 'none',
                        overflow: 'auto',
                        flexWrap: "nowrap",
                        display: "flex"
                    }}
                >
                    <Grid item xs className="logo" sx={{
                        /* maxWidth: "75%!important",
                        minWidth: "75%!important",
                        width: "75%!important", */
                        transition: '.5s',
                        mb: 3,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",

                    }}>

                        <form onSubmit={formik.handleSubmit} style={{ maxWidth: "500px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding: "40px 20px", backgroundColor: "white" }}>
                            <img style={{
                                margin: "0 auto",
                                marginBottom: "20px",
                                display: "flex"
                            }} src={Sicarx_Vertical_Light_Icon} alt="logoSX" width="190px" />
                            <TextFieldStyled
                                className="form-input"
                                fullWidth
                                id="email"
                                name="email"
                                sx={{ mt: 4, marginBottom: "20px" }}
                                variant="standard"
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                autoFocus={EMAIL_PARAM == null}
                                inputProps={{ autoComplete: 'on' }}
                                label={t(TGLOBAL.EMAIL, { ns: 'global' })}
                                onKeyUp={e => e.key === 'Enter' && formik.submitForm()}
                                helperText={formik.touched.email && formik.errors.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                            />

                            <TextFieldStyled
                                fullWidth
                                className="form-input"
                                sx={{ mt: 1, marginBottom: "20px" }}
                                id="password"
                                name="password"
                                variant="standard"
                                autoFocus={!!EMAIL_PARAM}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                inputProps={{ autoComplete: 'on' }}
                                type={showPassword ? 'text' : 'password'}
                                label={t(TGLOBAL.PASSWORD, { ns: 'global' })}
                                onKeyUp={e => e.key === 'Enter' && formik.submitForm()}
                                helperText={formik.touched.password && formik.errors.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" sx={{ mb: '10px !important' }}>
                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            {showAlert &&
                                <Alert sx={{ mt: 2, marginBottom: "20px" }} onClose={() => setShowAlert(false)} severity="error">
                                    {t(TACCOUNT.LOGIN_INCORRECTDATA_MSG_ERR, { ns: 'account' })}
                                </Alert>
                            }

                            <Button
                                fullWidth
                                type="submit"
                                id="btnLogin"
                                disableElevation
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    mt: 3,
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    p: '8px 0px 8px 0px',
                                    background: "rgb(8, 93, 169)",
                                    marginBottom: "5%"
                                }}
                            >
                                {isLoading &&
                                    <CircularProgress
                                        sx={{
                                            width: '20px !important',
                                            height: '20px !important',
                                            mr: 1, mb: '5px',
                                            color: 'white'
                                        }}
                                    />
                                }
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {t(TGLOBAL.LOGIN, { ns: 'global' })}
                                </Typography>
                            </Button>
                        </form>
                    </Grid>

                    <Grid item xs className="footer" sx={{ my: 3, height: "20px", minHeight: "20px", maxHeight: "20px" }}>
                        <Typography sx={{ userSelect: 'none', textAlign: 'center', color: "#424242" }}>
                            SICAR Solutions SA de CV
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* } */}
        </>
    )
})